import React from 'react'
import Layout from '../components/layout-v2';


const Page = () => (

  <Layout title="Contact us">
    <section className="section-contact">


      <h4>अधिकृत परख केंद्र -</h4>
      <section className="row distributor">

        <div className="col-md-4 col-xs-12">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Parakh Computer Center</h5>
              <p className="card-text">
                <strong>Smt. Rekha Patidar</strong><br/>
                <span data-translate="ADD-PARAKH-LINE1">Indrani Bhavan</span>,<br/>
                <span data-translate="ADD-PARAKH-LINE2">Bawadi Bus Station</span>,<br/>
                <span data-translate="ADD-PARAKH-LINE3">Khargone - 451001</span><br/>
                <span data-translate="ADD-PARAKH-LINE4">Madhya Pradesh</span><br/>
                <span className="fa fa-envelope fa-fw"></span><span data-translate="EMAIL">E-mail</span>:
                <a href="mailto:contact@parakh.me">patidar.cl@gmail.com</a><br/>
                <abbr title="Mobile Phone"><span className="fa fa-mobile fa-fw"></span>M:</abbr> 9826883403<br/>

                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d925.9877772454532!2d75.6104329881298!3d21.831895844012994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd885d195d53493%3A0x152048c4ac5e3d3e!2sParakh+Shikshan+Sewa+Sanstha!5e0!3m2!1sen!2sin!4v1515244266547"
                        frameborder="0" style={{border:0}} allowFullScreen></iframe>
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-4 col-xs-12">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Parakh Computer Center</h5>
              <p className="card-text">
                <strong>Shri. Dhiraj Suryawanshi</strong><br/>
                <span data-translate="ADD-PARAKH-LINE1">Dhareshwar Road</span>,<br/>
                <span data-translate="ADD-PARAKH-LINE2">Next to Malushah Bawdi</span>,<br/>
                <span data-translate="ADD-PARAKH-LINE3">Dhar - 454001</span><br/>
                <span data-translate="ADD-PARAKH-LINE4">Madhya Pradesh</span><br/>
                <span className="fa fa-envelope fa-fw"></span><span data-translate="EMAIL">E-mail</span>:
                <a href="mailto:contact@parakh.me">suryawanshidhiraj16@gmail.com</a><br/>
                <abbr title="Mobile Phone"><span className="fa fa-mobile fa-fw"></span>M:</abbr> 9993355794<br/>

                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1841.7722566699802!2d75.2927532581886!3d22.59613297260622!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962368752a0cccf%3A0xd8b66ac23cc75705!2sParakh+Computer+Center!5e0!3m2!1sen!2sin!4v1515402235992" frameborder="0" style={{border:0}} allowFullScreen></iframe>
              </p>
            </div>
          </div>

        </div>

      </section>

    </section>
  </Layout>
);

export default Page;

/*
<!--  विक्रेता -->
<!-- 21.831883, 75.610982 -->
                <!--
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1180.301852876474!2d75.6104329881298!3d21.831895844012994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd885d195d53493%3A0x152048c4ac5e3d3e!2sParakh+Shikshan+Sewa+Sanstha!5e1!3m2!1sen!2sin!4v1515244029383"
                        frameborder="0" style="border:0" allowFullScreen></iframe>
                -->

<!-- 22.5960551,75.2938195 -->
 */

